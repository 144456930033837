<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'
import moment from 'moment'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_cmdn',
      lkps_table_id: parseInt(this.$route.params.lkps_table_id),
      headers: [
        [{
          label: 'Tahun Akademik',
          name: 'tahun_akademik',
          dataKey: 'tahun_akademik',
          order: 1,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center align-middle' },
        }, {
          label: 'Jumlah Provinsi',
          name: 'jml_provinsi',
          dataKey: 'jml_provinsi',
          order: 2,
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center align-middle' },
        }, {
          label: 'Jumlah Calon Mahasiswa Regular',
          attributes: { colspan: 2 },
        }, {
          label: 'Jumlah Total Mahasiswa Reguler',
          name: 'jtmr',
          dataKey: 'jtmr',
          order: 5,
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center align-middle' },
        }],
        [{
          label: 'Laki-laki',
          name: 'jcmr_l',
          dataKey: 'jcmr_l',
          order: 3,
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center align-middle' },
        }, {
          label: 'Perempuan',
          name: 'jcmr_p',
          dataKey: 'jcmr_p',
          order: 4,
          calculate: true,
          calculateAttributes: { class: 'text-center' },
          dataAttributes: { class: 'text-center align-middle' },
        }]
      ],
      form: {
        inputs: [{
          label: 'Tahun Akademik',
          name: 'tahun_akademik',
          dataKey: 'tahun_akademik',
          type: 'textarea',
          rules: 'required',
          placeholder: moment().format('YYYY')
        }, {
          label: 'Jumlah Provinsi',
          name: 'jml_provinsi',
          dataKey: 'jml_provinsi',
          type: 'textarea',
          placeholder: 'Kalimantan Timur, Kalimantan Barat, Kalimantan Selatan, Kalimantan Utara'
        }, {
          label: 'Laki-laki',
          name: 'jcmr_l',
          dataKey: 'jcmr_l',
          type: 'number',
          
          defaultValue: 0,
          onChange(value, formData) {
            formData.create.jtmr = value + formData.create.jcmr_p
            formData.edit.jtmr = value + formData.create.jcmr_p
          }
        }, {
          label: 'Perempuan',
          name: 'jcmr_p',
          dataKey: 'jcmr_p',
          type: 'number',
          
          defaultValue: 0,
          onChange(value, formData) {
            console.log(123)
            formData.create.jtmr = value + formData.create.jcmr_l
            formData.edit.jtmr = value + formData.create.jcmr_l
          }
        }, {
          label: 'Jumlah Total Mahasiswa Reguler',
          name: 'jtmr',
          dataKey: 'jtmr',
          type: 'number',
          
          defaultValue: 0,
        }]
      }
    }
  },
}
</script>
